const axios = require('axios');

function checkUserRecipe(search) {
	if (!search) return;

	const searchInput = $('input[name="name"]');
	const searchResult = $('.search_result');

	searchInput.blur(function(){

	axios
		.get(`/api/userRecipeCheck?q=${this.value}`)
		.then(res => {
			if(res.data.length) {
				$('.search_result').show().html('Receptura o takiej nazwie już istnieje');
			}
		});
	});

	searchInput.focus(function(){
		$('.search_result').hide();
	});
}

export default checkUserRecipe;